<template>
  <div class="error">
    <div class="e404">
      <div class="txt">404</div>
      <div>抱歉， 您访问的页面不存在，请重新加载！</div>
      <div class="row">
        <el-button class="btn" type="text" @click="$router.go(0);">
          <i class="el-icon-refresh"></i>刷新
        </el-button>
        <el-button class="btn" type="text" @click="$router.replace('/');">
          <i class="el-icon-arrow-left"></i>返回首页
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.error {
  height: 100vh;
}
.e404 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .txt {
    font-size: 170px;
    font-weight: 900;
    text-shadow: 10px 30px 150px $primary-color;
  }
  .row {
    display: flex;
    flex-direction: row;
    color: $primary-color;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    .btn {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>
